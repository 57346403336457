// App.js
import React from "react";
import {
  CheckoutPage,
  EDPage,
  ErrorPage,
  HomePage,
  HomePage2,
  InakeForm,
  LoginPage,
  Page4,
  Page5,
  Page6,
  WeightLossPage,
} from "./pages";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SucessPage from "./pages/SucessPage";
import { AuthProvider } from "./context/AuthContext";
import PrivateRoute from "./utils/PrivateRoute";

const App = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginPage />} /> {/* Public Route */}
          {/* <Route path="/" element={<HomePage />} /> */}
          {/* Private Routes */}
          <Route
            path="/"
            element={
              <PrivateRoute>
                <HomePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/sexual-health"
            element={
              <PrivateRoute>
                <EDPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/home-page"
            element={
              <PrivateRoute>
                <HomePage2 />
              </PrivateRoute>
            }
          />
          <Route
            path="/semaglutide"
            element={
              <PrivateRoute>
                <Page4 />
              </PrivateRoute>
            }
          />
          <Route
            path="/tirzepatide"
            element={
              <PrivateRoute>
                <Page5 />
              </PrivateRoute>
            }
          />
          <Route
            path="/hair-loss"
            element={
              <PrivateRoute>
                <Page6 />
              </PrivateRoute>
            }
          />
          <Route
            path="/weight-loss"
            element={
              <PrivateRoute>
                <WeightLossPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/inake-form"
            element={
              <PrivateRoute>
                <InakeForm />
              </PrivateRoute>
            }
          />
          <Route
            path="/SuccessPage"
            element={
              <PrivateRoute>
                <SucessPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/ErrorPage"
            element={
              <PrivateRoute>
                <ErrorPage />
              </PrivateRoute>
            }
          />
          {/* <Route
            path="/:id"
            element={
              <PrivateRoute>
                <CheckoutPage />
              </PrivateRoute>
            }
          /> */}
          {/* <Route path="*" element={<ErrorPage />} /> */}
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default App;
