import React from "react";
import "./WhatIsHairLoss.css";

const WhatIsHairLoss = () => {
  return (
    <>
      <div className="dysfunction2-section">
        <div className="dysfunction-content">
          <h1 className="dysfunction_h1">WHAT IS HAIR LOSS?</h1>
          <p className="dysfunction_p">
            The primary cause of male-pattern baldness is believed to be genetic
            and related to hormones, specifically the hormone
            dihydrotestosterone (DHT). In individuals with a genetic
            predisposition to this condition, hair follicles in certain areas of
            the scalp become sensitive to the effects of DHT. Over time,
            exposure to DHT causes these follicles to shrink and produce thinner
            and shorter hair until they eventually stop producing hair
            altogether.
          </p>
        </div>
      </div>
    </>
  );
};

export default WhatIsHairLoss;
