import React from "react";
import "./WhatsIndclude.css";
import { FaChevronRight } from "react-icons/fa";
import images from "../../constants/images";

const WhatsIndclude = ({ onClickHandle }) => {
  return (
    <div className="section section-center">
      <div className="wi_main_wrapp">
        <div className="wi_sec1_wrapp">
          <h2 className="heading2 mb-2">WHAT'S INCLUDED?</h2>
          <div className="wi_des_text_wrapp">
            <p>Includes all private confidential telehealth consultations</p>
            <p>Doctor prescribed medication and all supplies</p>
            <p>Fast, discreet and free delivery</p>
            <p>Ongoing support and messaging</p>
          </div>
          <button onClick={onClickHandle && onClickHandle} class="btn mt-2">
            <p>start my visit</p>
            <FaChevronRight />
          </button>
        </div>
        <div className="wi_sec2_wrapp">
          <img src={images.product_1} alt="" />
        </div>
      </div>
    </div>
  );
};

export default WhatsIndclude;
