import React, { useState, useEffect, useRef } from "react";
import "./Navbar.css";
import { images } from "../../constants";
import { Link, NavLink } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false); // For mobile hamburger menu
  const [submenuOpen, setSubmenuOpen] = useState(false); // For "Other Meds" submenu (desktop)
  const [mobilesubmenuOpen, setMobileSubmenuOpen] = useState(false); // For mobile submenu

  const submenuRef = useRef(null); // Ref for submenu

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleSubmenu = () => {
    setSubmenuOpen(!submenuOpen); // Toggle desktop submenu visibility
  };

  const toggleMobileSubmenu = () => {
    setMobileSubmenuOpen(!mobilesubmenuOpen);
  };

  const closeSubmenu = () => {
    setSubmenuOpen(false); // Close desktop submenu
  };

  // Close submenu when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (submenuRef.current && !submenuRef.current.contains(event.target)) {
        closeSubmenu();
      }
    };

    // Add event listener for clicks outside
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="nav_main_wrapp">
      <div className="section nav_base">
        <Link to="/" className="nav_logo_wrapp">
          <img src={images.white_logo} alt="Logo" />
          <p>MAXIMUM HEALTH & WELLNESS</p>
        </Link>
        <div className={`nav_secound_sec ${isOpen ? "open" : ""}`}>
          <div></div>
          {/* <NavLink
            to="/weight-loss"
            className={({ isActive }) => (isActive ? "active-tab" : "")}
          >
            WEIGHT LOSS
          </NavLink> */}
          {/* <NavLink
            to="/hair-loss"
            className={({ isActive }) => (isActive ? "active-tab" : "")}
          >
            HAIR LOSS
          </NavLink>
          <NavLink
            to="/sexual-health"
            className={({ isActive }) => (isActive ? "active-tab" : "")}
          >
            SEXUAL HEALTH
          </NavLink> */}

          {/* Desktop submenu */}
          {/* <div
            className="other-meds-wrapper"
            ref={submenuRef}
            onMouseEnter={() => setSubmenuOpen(true)} // For desktop hover
            // onMouseLeave={closeSubmenu}
          >
            <button className="other-meds-button" onClick={toggleSubmenu}>
              OTHER MEDS
              <span className="arrow"></span>
            </button>

            {submenuOpen && (
              <div className="submenu">
                <Link to="/semaglutide" onClick={closeSubmenu}>
                  Semaglutide
                </Link>
                <Link to="/tirzepatide" onClick={closeSubmenu}>
                  Tirzepatide
                </Link>
              </div>
            )}
          </div> */}

          {/* <div className="other-meds-wrapper"> */}
          <NavLink
            to="/weight-loss"
            className={({ isActive }) => (isActive ? "active-tab" : "")}
          >
            WEIGHT LOSS
          </NavLink>
          <button 
            className="login_button"
            onClick={() => window.location.href = "https://joinmensrx.com/memberportal"}>
            LOGIN
          </button>
          {/* </div> */}

          {/* Mobile view accordion */}
          {/* <div className="mobile-accordion">
            <li>
              <span className="accordion-title" onClick={toggleMobileSubmenu}>
                OTHER MEDS
                <span className="arrow"></span>
              </span>
              {mobilesubmenuOpen && (
                <ul className="mobile-submenu">
                  <li>
                    <Link to="/semaglutide" onClick={closeSubmenu}>
                      Semaglutide
                    </Link>
                  </li>
                  <li>
                    <Link to="/tirzepatide" onClick={closeSubmenu}>
                      Tirzepatide
                    </Link>
                  </li>
                </ul>
              )}
            </li>
          </div> */}

          {/* <button>LOG IN</button> */}
        </div>

        <div className="hamburger-menu" onClick={toggleMenu}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
