import React, { useState } from "react";
import "./SecoundHero.css";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { GoCheckCircle } from "react-icons/go";
import { images } from "../../constants";

const SecoundHero = () => {
  // Define the initial state for the selected medicine
  const [selectedMedicine, setSelectedMedicine] = useState({
    name: "Generic Viagra",
    amount: "20",
  });
  // Define medicine options
  const medicineOptions = [
    { name: "Generic Viagra", amount: "20" },
    { name: "Generic Cialis", amount: "200" },
    { name: "Generic Daily Cialis", amount: "300" },
  ];

  // Function to handle option change
  const handleOptionChange = (option) => {
    setSelectedMedicine(option);
  };
  return (
    <div className="sec_page_hero_main_wrapp">
      <div className="sec_page_hero_base_wrapp">
        <div className="sec_page_hero_left">
          <img src={images.second_hero} alt="" />
        </div>
        <div className="sec_page_hero_right">
          <h1>
            The #1 ED meds at unbeatable prices, delivered straight to your
            door.
          </h1>
          <div className="sec_hero_content_div">
            <div className="sec_hero_content_left">
              <div className="sec_hero_option_div">
                {medicineOptions.map((option, index) => (
                  <button
                    key={index}
                    onClick={() => handleOptionChange(option)}
                    className={`option-circle ${
                      selectedMedicine.name === option.name ? "active" : ""
                    }`}
                  >
                    {/* Small circle before the option name */}
                    <span
                      className={`small-circle ${
                        selectedMedicine.name === option.name
                          ? "active-circle"
                          : ""
                      }`}
                    ></span>
                    {option.name}
                  </button>
                ))}
              </div>

              <div className="sec_hero_med_del">
                <div>
                  <h3 className="sec_med_name">{selectedMedicine.name}</h3>
                  <p className="sec_shipment">6 tablets per shipment</p>
                </div>
                <div>
                  <p className="sec_startingat">Starting at</p>
                  <h3 className="sec_med_name">
                    <span className="dollor_symbol">$</span>
                    {selectedMedicine.amount}
                  </h3>
                </div>
              </div>

              <button className="start_my_visit_btn btn">
                COMING SOON
                <MdOutlineKeyboardArrowRight fontSize={30} />
              </button>
            </div>
            <div className="sec_hero_content_right">
              <div className="grey-circle"></div>
              <img src={images.mens_medicine} alt="" />
            </div>
          </div>
          <div className="sec_hero_last_div">
            <p>
              <div>
                <GoCheckCircle fontSize={25} />
              </div>
              <span>100% online, confidential consultations</span>
            </p>
            <p>
              <div>
                <GoCheckCircle fontSize={25} />
              </div>
              <span>US board-certified doctors, trusted medications</span>
            </p>
            <p>
              <div>
                <GoCheckCircle fontSize={25} />
              </div>
              <span>Free rushed delivery from the pharmacy</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecoundHero;
