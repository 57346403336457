import React, { useEffect } from "react";
import { Footer, Navbar } from "../../common";
import {
  ClientSlider,
  GetPrescription,
  Glp1Section,
  HeroSection4,
  HeroSection6,
  ThreeButtons,
  WhatIsSemaglutide,
} from "../../containers";
import { FAQ } from "../../components";
import { images } from "../../constants";
import ClientSliderShadow from "../../containers/clientslider/ClientSliderShadow";

const Page4 = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);
  return (
    <>
      <Navbar />
      <HeroSection4 />
      <ClientSliderShadow />
      <WhatIsSemaglutide
        image={images.man_with_apple}
        name="What is Semaglutide?"
        des1="  Semaglutide is a prescription medication that is prescribed for
                  weight loss and Type 2 Diabetes. It belongs to a class of drugs
                  called glucagon-like peptide-1 (GLP-1) receptor agonists. It
                  mimics the action of GLP-1, a hormone that stimulates the release
                  of insulin and reduces the amount of glucose produced by the
                  liver. This helps lower blood sugar levels in people with
                  diabetes."
        des2="Semaglutide also reduces the speed at which food passes through
                  the digestive system. Semaglutide prolongs digestion, helping to
                  maintain your feeling of fullness for a longer period. Therefore,
                  when you take Semaglutide injections, you feel fuller faster and
                  are less likely to overeat."
        des3=""
        btn1="Average 15% of body weight loss"
      />
      <GetPrescription />
      <ThreeButtons
        name1="NO INSURANCE HASSULE"
        name2="NO OUT-OF-STOCK ANXIETY"
        name3="CONNECT WITH A REAL PHYSICIAN"
      />
      <FAQ />
      <Footer />
    </>
  );
};

export default Page4;
