import React from "react";
import "./cardsection.css";

// import image1 from './../../assets/images/card1.png';
// import image2 from './../../assets/images/card2.png';
// import image3 from './../../assets/images/card3.png';
// import image4 from './../../assets/images/card4.png';
import { images } from "../../constants";

const CardSection = () => {
  return (
    <div className="card_section">
      <div className="card  card_first">
        <div className="card_content">
          <h2 className="card_h2">15%</h2>
          <p className="card_p">
            reduction <br /> in body <br /> weight
          </p>
        </div>
        <img
          src={images.card_img1}
          alt="Weight reduction"
          className="card_image"
        />
      </div>
      <div className="card">
        <p className="card_p_feel">
          Easy to administer <br /> weekly shot
        </p>
        <img src={images.card_img2} alt="Weekly shot" className="card_image2" />
      </div>
      <div className="card">
        <p className="card_p_feel">
          Feel fuller <br /> faster & longer
        </p>
        <img src={images.card_img3} alt="Feel fuller" className="card_image2" />
      </div>
      <div className="card  card_last">
        <p className="card_p2">
          <span className="card_same">Same active ingredients</span> <br />
          as Ozempic® and Wegovy®
        </p>
        <img
          src={images.card_img4}
          alt="Active ingredients"
          className="card_image second_img"
        />
      </div>
    </div>
  );
};

export default CardSection;
