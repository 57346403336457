import React from "react";
import "./getyourprescription.css";

import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { images } from "../../constants";
const GetYourPrescription = ({ onClickHandle }) => {
  return (
    <div className="get_prescription_main_wrapp">
      <div className="get_prescription_base_wrapp">
        <h2 className="heading2">GET YOUR PRESCRIPTION IN 3 EASY STEPS</h2>
        <div className="gp_card_div">
          {/* card1 */}
          <div className="gp_card">
            <div className="gp_card_left">
              <img src={images.man_with_towel} alt="" />
            </div>
            <div className="gp_card_right">
              <h3>TAKE THE HEALTH QUESTIONNAIRE</h3>
              <p>Complete a short assessment of your medical history.</p>
            </div>
            <div className="green-triangle">
              <span>1</span>
            </div>
          </div>
          {/* card2 */}
          <div className="gp_card">
            <div className="gp_card_left">
              <img src={images.male_doctor} alt="" style={{objectPosition:"center"}}/>
            </div>
            <div className="gp_card_right">
              <h3>DOCTOR REVIEW</h3>
              <p>
                A licensed physician will review your answers and prescribe the
                appropriate treatment.
              </p>
            </div>
            <div className="green-triangle">
              <span>2</span>
            </div>
          </div>
          {/* card3 */}
          <div className="gp_card">
            <div className="gp_card_left">
              <img src={images.box_img} alt="" style={{objectPosition:"center"}}/>
            </div>
            <div className="gp_card_right">
              <h3>RECEIVE YOUR MEDICATION</h3>
              <p>
                We will deliver your order discreetly and for FREE within a few
                days upon approval.
              </p>
            </div>
            <div className="green-triangle">
              <span>3</span>
            </div>
          </div>

          <button className="btn" onClick={onClickHandle && onClickHandle}>
            START MY VISIT
            <MdOutlineKeyboardArrowRight fontSize={30} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default GetYourPrescription;
