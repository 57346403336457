import React from "react";
import "./HeroSection3.css";

import { FaCircleCheck } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";
import { images } from "../../constants";

const HeroSection3 = ({ onClickHandle, data }) => {
  return (
    <div className="weight-loss-section" style={{ width: "100vw" }}>
      <div className="image-container">
        <img src={images.body_man} alt="Weight Loss" className="left-image" />
      </div>

      <div className="content-container">
        <h1 className="weight_h1">
          Crush your weight loss <br /> goals with GLP-1
        </h1>

        <div className="benefits-button-section">
          <div>
            <ul className="benefits-list">
              <li>
                <FaCircleCheck className="icon_check" /> Trusted Medications
              </li>
              <li>
                <FaCircleCheck className="icon_check" /> Certified US based
                healthcare team
              </li>
              <li>
                <FaCircleCheck className="icon_check" /> Speedy and free
                delivery
              </li>
              <li>
                <FaCircleCheck className="icon_check" /> Best possible prices
              </li>
            </ul>
            <button className="btn" onClick={onClickHandle}>
              START MY VISIT <FaAngleRight />
            </button>
          </div>
          <div className="bottle-images">
            <img src={images.medical1} alt="Bottle 1" className="bottle1" />
            <img src={images.medical2} alt="Bottle 2" className="bottle2" />
            <img
              src={images.HIPAA_white}
              alt="Weight Loss"
              className="hipa-img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection3;
