import React, { useState, useEffect, useRef } from "react";
import "./NavbarBlack.css";
import { images } from "../../constants";
import { Link, NavLink } from "react-router-dom";

const NavbarBlack = () => {
  const [isOpen, setIsOpen] = useState(false); // For mobile hamburger menu
  const [submenuOpen, setSubmenuOpen] = useState(false); // For "Other Meds" submenu (desktop)
  const [mobilesubmenuOpen, setMobileSubmenuOpen] = useState(false); // For mobile submenu

  const submenuRef = useRef(null); // Ref for submenu

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleSubmenu = () => {
    setSubmenuOpen(!submenuOpen); // Toggle desktop submenu visibility
  };

  const toggleMobileSubmenu = () => {
    setMobileSubmenuOpen(!mobilesubmenuOpen);
  };

  const closeSubmenu = () => {
    setSubmenuOpen(false); // Close desktop submenu
  };

  // Close submenu when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (submenuRef.current && !submenuRef.current.contains(event.target)) {
        closeSubmenu();
      }
    };

    // Add event listener for clicks outside
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="nav_main_wrapp_black">
      <div className="section nav_base">
        <Link to="/" className="nav_logo_wrapp">
          <img src={images.black_logo} alt="Logo" />
        </Link>
        <div className={`nav_secound_sec_blk ${isOpen ? "open" : ""}`}>
          <div></div>
          {/* <NavLink
            style={{ color: "#fff" }}
            to="/hair-loss"
            className={({ isActive }) => (isActive ? "active-tab" : "")}
          >
            HAIR LOSS
          </NavLink>
          <NavLink
            to="/sexual-health"
            style={{ color: "#fff" }}
            className={({ isActive }) => (isActive ? "active-tab" : "")}
          >
            SEXUAL HEALTH
          </NavLink>
          <div
            className="other-meds-wrapper title-white"
            ref={submenuRef}
            onMouseEnter={() => setSubmenuOpen(true)}
            onMouseLeave={() => setSubmenuOpen(false)}
          >
            <button
              className="other-meds-button2"
              onClick={toggleSubmenu} // Toggle on click
            >
              OTHER MEDS
              <span className="arrow"></span>
            </button>

            {submenuOpen && (
              <div className="submenu_blk">
                <Link to="/semaglutide" onClick={closeSubmenu}>
                  Semaglutide
                </Link>
                <Link to="/tirzepatide" onClick={closeSubmenu}>
                  Tirzepatide
                </Link>
              </div>
            )}
          </div> */}
          <NavLink
            style={{ color: "#fff" }}
            to="/weight-loss"
            className={({ isActive }) => (isActive ? "active-tab" : "")}
          >
            WEIGHT LOSS
          </NavLink>
          <button 
            className="login_button_blk"
            onClick={() => window.location.href = "https://joinmensrx.com/memberportal"}>
            LOGIN
          </button>
          {/* Mobile view accordion */}
          {/* <div className="mobile-accordion">
            <li>
              <span
                className="accordion-title title-white"
                onClick={toggleMobileSubmenu}
              >
                OTHER MEDS
                <span className="arrow"></span>
              </span>
              {mobilesubmenuOpen && (
                <ul className="mobile-submenu title-white">
                  <li>
                    <Link to="/semaglutide" onClick={closeSubmenu}>
                      Semaglutide
                    </Link>
                  </li>
                  <li>
                    <Link to="/tirzepatide" onClick={closeSubmenu}>
                      Tirzepatide
                    </Link>
                  </li>
                </ul>
              )}
            </li>
          </div> */}

          {/* <button className="nav_black_login_btn">LOG IN</button> */}
        </div>
        <div className="hamburger-menu-black" onClick={toggleMenu}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
      </div>
    </div>
  );
};

export default NavbarBlack;
