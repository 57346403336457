import React, { useState } from "react";
import "./FAQ2.css";
import { FaAngleRight, FaArrowCircleRight } from "react-icons/fa";
import images from "../../constants/images";
import { IoArrowDownCircle } from "react-icons/io5";

const FAQ2 = () => {
  const [openFAQ, setOpenFAQ] = useState(null);

  const toggleFAQ = (index) => {
    setOpenFAQ(openFAQ === index ? null : index);
  };

  return (
    <div className="faq-container2">
      <h2 className="faq-title2">Frequently Asked Questions</h2>

      <div className="faq-item2">
        <div className="faq-question2" onClick={() => toggleFAQ(1)}>
          <span>WHAT IS MENS RX?</span>
          <IoArrowDownCircle
            fontSize={30}
            className={`faq-img2 ${openFAQ === 1 ? "rotate2" : ""}`}
          />
        </div>
        {openFAQ === 1 && (
          <div className="faq-answer2">
            <p>
              Mens RX is a health service designed to help men achieve optimal
              wellness through personalized treatment.
            </p>
          </div>
        )}
      </div>
      <hr className="faq-hr2" />

      <div className="faq-item2">
        <div className="faq-question2" onClick={() => toggleFAQ(2)}>
          <span>WHAT IS THE MENS RX PROCESS?</span>
          <IoArrowDownCircle
            fontSize={30}
            className={`faq-img2 ${openFAQ === 2 ? "rotate2" : ""}`}
          />
        </div>
        {openFAQ === 2 && (
          <div className="faq-answer2">
            <p>
              The process includes a medical evaluation, personalized treatment
              plan, and follow-up support to ensure the best results.
            </p>
          </div>
        )}
      </div>
      <hr className="faq-hr2" />

      <div className="faq-item2">
        <div className="faq-question2" onClick={() => toggleFAQ(3)}>
          <span>HOW MUCH DOES MENS RX COST?</span>
          <IoArrowDownCircle
            fontSize={30}
            className={`faq-img2 ${openFAQ === 3 ? "rotate2" : ""}`}
          />
        </div>
        {openFAQ === 3 && (
          <div className="faq-answer2">
            <p>
              The cost of Mens RX varies based on the treatment plan and
              services selected. Contact us for more details.
            </p>
          </div>
        )}
      </div>
      <hr className="faq-hr2" />

      <div className="faq-footer2">
        <button className="visit-button2">
          COMING SOON <FaArrowCircleRight color="#000" />
        </button>
      </div>
    </div>
  );
};

export default FAQ2;
