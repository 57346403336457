import React from "react";
import "./ThreeButtons.css";

const ThreeButtons = ({ name1, name2, name3 }) => {
  return (
    <div className="threebutton_main_wrapp">
      <div className="threebutton_base_wrapp">
        <button>{name1}</button>
        <button>{name2}</button>
        <button>{name3}</button>
      </div>
    </div>
  );
};

export default ThreeButtons;
