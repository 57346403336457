import React, { useEffect, useRef, useState } from "react";
import { Footer, Navbar } from "../../common";
import {
  ClientSlider,
  GetYourPrescription,
  Mensrxhero,
  ThreeButtons,
  TrustedMedication,
  WhatsIndclude,
} from "../../containers";
import { FAQ } from "../../components";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { products_url } from "../../utils/Constant";

const HomePage = () => {
  const [getloading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const section1Ref = useRef(null);
  const navigate = useNavigate();

  // Fetch product data and save form_id values in localStorage
  const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(products_url);
      if (response.data.status === 1) {
        const products = response.data.product;
        setData(products);
        setLoading(false);
        // Store each form_id in localStorage
        products.forEach((item) => {
          if (item.form_id) {
            localStorage.setItem(`form_id`, item.form_id);
          }
        });
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch products:", error);
      setLoading(false);
    }
  };

  const formId = localStorage.getItem(`form_id`);

  const onClickHandle = () => {
    navigate("/weight-loss");
  };

  useEffect(() => {
    // Scroll to the top on load
    window.scrollTo({ top: 0, behavior: "instant" });

    // Fetch products and save form_id values on page load
    fetchProducts();
  }, []);

  return (
    <>
      <Navbar />
      <Mensrxhero onClickHandle={onClickHandle} />
      <TrustedMedication />
      <WhatsIndclude onClickHandle={onClickHandle} />
      <ThreeButtons
        name1="NO INSURANCE HASSULE"
        name2="NO OUT-OF-STOCK ANXIETY"
        name3="CONNECT WITH A REAL PHYSICIAN"
      />
      <GetYourPrescription onClickHandle={onClickHandle} />
      <ClientSlider />
      <FAQ onClickHandle={onClickHandle} />
      <Footer />
    </>
  );
};

export default HomePage;
