import React from "react";
import "./ProductCard.css";
import { FaAngleRight } from "react-icons/fa";
import { images } from "../../constants";
import { Link } from "react-router-dom";

const ProductCard = ({
  image,
  name,
  price,
  description,
  onClickHandle,
  item,
  navigate,
}) => {
  console.log("image", image);
  return (
    <div
      className="product_card_main_wrapp"
      // onClick={() => {
      //   navigate("/inake-form", {
      //     state: {
      //       form_id: item.form_id,
      //     },
      //   });
      // }}
    >
      <div className="product_card_img_wrapp">
        <img src={image} alt="" />
      </div>
      <div className="product_card_text_wrapp">
        <label>{name}</label>
        <h5>Starting at ${price}</h5>
        <div className="product_card_hr"></div>
        <p>{description}</p>
        {/* <div
          onClick={navigate("/inake-form", {
            state: {
              form_id: item.form_id,
            },
          })}
          className="btn mt-1"
        >
          View <FaAngleRight />
        </div> */}
        <Link
          to="/inake-form"
          state={{ form_id: item.form_id }}
          className="btn mt-1"
          style={{ marginTop: "10px", width: "100%", minWidth: "auto" }}
        >
          START MY VISIT <FaAngleRight />
        </Link>
      </div>
    </div>
  );
};

export default ProductCard;
