import React from "react";
import Slider from "react-slick"; // Import react-slick
import "./ClientSlider.css"; // Your custom styles
import "slick-carousel/slick/slick.css"; // Slick styles
import "slick-carousel/slick/slick-theme.css"; // Slick theme
import images from "../../constants/images";

const ClientSlider = () => {
  const settings = {
    infinite: true,
    speed: 10000,
    slidesToShow: 8,
    slidesToScroll: 8, // Scroll all visible slides at once for smoother effect
    autoplay: true,
    autoplaySpeed: 0,
    arrows: false,
    cssEase: "linear",
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className="client_slider_main_wrapp">
      <div className="client_slider_base_wrapp">
        <div className="client_slider_div">
          <div className="cs_title">
            <h2>AS SEEN ON</h2>
          </div>
          <Slider {...settings} className="client_slider">
            <div className="client_slider_img">
              <img src={images.mens_fitness} alt="mens_fitness" />
            </div>
            <div className="client_slider_img">
              <img src={images.vogue} alt="vogue" />
            </div>
            <div className="client_slider_img ">
              <img
                src={images.cnnlogo}
                alt="Mens Fitness"
                className="cnnlogo"
              />
            </div>
            <div className="client_slider_img">
              <img src={images.today} alt="today" />
            </div>

            <div className="client_slider_img">
              <img src={images.Fox_News} alt="fox new" className="foxlogo" />
            </div>
            <div className="client_slider_img">
              <img src={images.NBC_logo} alt="NBC" />
            </div>
            <div className="client_slider_img">
              <img src={images.GQ_Logo} alt="gq logo" className="cnnlogo" />
            </div>
            <div className="client_slider_img">
              <img src={images.Yahoo_news_logo} alt="yahoo news" />
            </div>
            {/* <div className="client_slider_img">
              <img src={images.vogue} alt="Mens Fitness" />
            </div> */}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default ClientSlider;
