import React, { useEffect, useRef, useState } from "react";
import "./WeightLossPage.css";
import {
  CardSection,
  ClientSlider,
  GetPrescription,
  Glp1Section,
  HeroSection3,
  HeroSection4,
  ThreeButtons,
  WeightCalculator,
} from "../../containers";
import { FAQ } from "../../components";
import { Footer, Navbar } from "../../common";
import { images } from "../../constants";
import axios from "axios";
import { products_url } from "../../utils/Constant";
import { useNavigate } from "react-router-dom";

const WeightLossPage = () => {
  const [getloading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const section1Ref = useRef(null);
  const navigate = useNavigate();

  // Fetch product data and save form_id values in localStorage
  const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(products_url);
      if (response.data.status === 1) {
        const products = response.data.product;
        setData(products);
        setLoading(false);
        // Store each form_id in localStorage
        products.forEach((item) => {
          if (item.form_id) {
            localStorage.setItem(`form_id`, item.form_id);
          }
        });
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch products:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    // Scroll to the top on load
    window.scrollTo({ top: 0, behavior: "instant" });

    // Fetch products and save form_id values on page load
    fetchProducts();
  }, []);

  const scrollToSection = () => {
    section1Ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const formId = localStorage.getItem(`form_id`);

  const onClickHandle = () => {
    navigate("/inake-form", { state: { form_id: formId } });
  };

  return (
    <>
      <Navbar />
      <HeroSection3 data={data} onClickHandle={onClickHandle} />
      <ClientSlider />
      <CardSection />
      {/* <WeightCalculator onClickHandle={onClickHandle} /> */}
      <div ref={section1Ref}></div>

      {/* Pass the fetched data as props to Glp1Section */}
      <Glp1Section data={data} />

      <GetPrescription />
      <ThreeButtons
        name1="NO INSURANCE HASSLE"
        name2="NO OUT-OF-STOCK ANXIETY"
        name3="CONNECT WITH A REAL PHYSICIAN"
      />
      <FAQ onClickHandle={onClickHandle} />
      <Footer />
    </>
  );
};

export default WeightLossPage;
