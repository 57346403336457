import React from "react";

const Loading = () => {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div className="loading"></div>
    </div>
  );
};

export default Loading;
