import React, { useEffect } from "react";
import { Footer, Navbar } from "../../common";
import {
  ClientSlider,
  GetPrescription,
  Glp1Section,
  HeroSection4,
  HeroSection5,
  HeroSection6,
  ThreeButtons,
  WhatIsSemaglutide,
} from "../../containers";
import { FAQ } from "../../components";
import { images } from "../../constants";
import ClientSliderShadow from "../../containers/clientslider/ClientSliderShadow";

const Page5 = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);
  return (
    <>
      <Navbar />
      <HeroSection5 />
      <ClientSliderShadow />
      <WhatIsSemaglutide
        image={images.man_with_selad}
        name="What is Tirzepatide?"
        des1="Tirzepatide injection is used to treat type 2 diabetes. It is used together with diet and exercise to help control your blood sugar. Tirzapatide is a glucagon-like peptide-1 (GLP-1) receptor agonist."
        des2="Tirzepatide injection is also used to help lose weight and keep the weight off in patients with obesity caused by certain conditions."
        des3="This medicine is available only with your doctor’s prescription."
        btn1="Average 25% of body weight loss"
      />
      <GetPrescription />
      <ThreeButtons
        name1="NO INSURANCE HASSULE"
        name2="NO OUT-OF-STOCK ANXIETY"
        name3="CONNECT WITH A REAL PHYSICIAN"
      />
      <FAQ />
      <Footer />
    </>
  );
};

export default Page5;
