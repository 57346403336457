import React from "react";
import "./GPCard.css";
import { FaChevronRight } from "react-icons/fa";

const GPCard = ({ image, name }) => {
  return (
    <div className="tm_card_wrapp">
      <div className="tm_card_img_wrapp" style={{ height: "280px" }}>
        <img src={image} alt="" />
      </div>
      <div className="gp_card_name_wrapp">
        <h4>{name}</h4>
      </div>
    </div>
  );
};

export default GPCard;
