import React, { useEffect, useState } from "react";
import "./Glp1Section.css";
import { ProductCard } from "../../components";
import { images } from "../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { products_url } from "../../utils/Constant";

const Glp1Section = ({ data }) => {
  const navigate = useNavigate();
  const onClickHandle = () => {
    navigate("/inake-form", { state: {} });
  };
  // const [data, setData] = useState([]);
  // const [getloading, setLoading] = useState(false);
  // const location = useLocation();

  // const AllProducts = async () => {
  //   setLoading(true);
  //   await axios
  //     .get(products_url)
  //     .then((response) => {
  //       setLoading(false);
  //       console.log("response.data", response.data);
  //       if (response.data.status == 1) {
  //         setData(response.data.product);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //       setLoading(false);
  //     });
  // };

  useEffect(() => {
    // AllProducts();
    const formId = localStorage.getItem(`form_id`);
    console.log("formId", formId);
  }, []);

  return (
    <div className="glp1_main_wrapp">
      <div className="section glp1_base_wrapp">
        <h2 className="glp1_sec_heading">
          GLP-1s may be prescribed for weight loss if appropriate.
        </h2>
        <p className="glp1_sec_desc">
          It’s a once-weekly shot that works by mimicking your body’s natural
          hormones, helping you feel fuller faster and for longer.
        </p>
        <div className="glp1_products_wrapp">
          {/* <ProductCard
            name="Semaglutide"
            price="00.00"
            image={images.semaglutide_img}
            description="Same Active Ingredient as Ozempic® and Wegovy®"
            onClickHandle={onClickHandle}
          /> */}

          {data && data.length > 0
            ? data
                .slice()
                .reverse()
                .map((item, index) => {
                  return (
                    <ProductCard
                      name={item.product_name}
                      price={item.product_price}
                      // image={item.tirzepatide_img}
                      image={
                        item?.product_img && item.product_img.length > 0
                          ? item.product_img.map((item) => item.img_video)
                          : images.medicine
                      }
                      description={item.product_description}
                      onClickHandle={onClickHandle}
                      item={item}
                      navigate={navigate}
                    />
                  );
                })
            : null}
        </div>
      </div>
    </div>
  );
};

export default Glp1Section;
