import React, { useState } from "react";
import "./footer.css";
import { images } from "../../constants";
import { Link } from "react-router-dom";

const Footer = () => {
  const [openFAQ, setOpenFAQ] = useState(null);

  const toggleFAQ = (index) => {
    setOpenFAQ(openFAQ === index ? null : index);
  };
  const currentYear = new Date().getFullYear();
  return (
    <div id="root">
      <div className="footer">
        <div className="footer-guarantee">
          <p>BACKED WITH OUR 60 DAY MONEY BACK GUARANTEE!</p>
        </div>

        <div className="footer-content">
          <div className="footer-left">
            <div className="footer-logo">
              <img
                src={images.black_logo}
                alt="MensRX Logo"
                className="footer-logo-img"
              />
              <hr />
              <div className="social-icons">
                <p>FOLLOW US</p>
                <div>
                  <a
                    href="https://instagram.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={images.instagram} alt="Instagram Icon" />
                  </a>
                  <a
                    href="https://facebook.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={images.facebook} alt="Facebook Icon" />
                  </a>
                </div>
              </div>
              <hr />
              <br />
              <p>Copyright &copy; {currentYear} mensrx.com</p>
            </div>
          </div>

          <div className="footer-right">
            <div className="footer-section_main">
              <div className="footer-section">
                <h4>WHAT WE TREAT</h4>
                <ul>
                  <li>
                    <Link to="/weight-loss">Weight Loss</Link>
                  </li>
                  <li>{/* <Link to="/hair-loss">Hair Loss</Link> */}</li>
                  <li>
                    {/* <Link to="/sexual-health">Erectile Dysfunction</Link> */}
                  </li>
                </ul>
              </div>
              <div className="footer-section">
                <h4>LEGAL</h4>
                <ul>
                  <li>
                    <Link to="/">HIPAA Notice</Link>
                  </li>
                  <li>
                    <Link to="/">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/">Returns & Refund Policy</Link>
                  </li>
                  <li>
                    <Link to="/">Terms Of Use</Link>
                  </li>
                  <li>
                    <Link to="/">CCPA Opt-Out</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
