import React from "react";
import "./WhatIsErectile.css";

const WhatIsErectile = () => {
  return (
    <>
      <div className="dysfunction-section">
        <div className="dysfunction-content">
          <h1 className="dysfunction_h1">
            What is Erectile <br /> Dysfunction?
          </h1>
          <p className="dysfunction_p">
            Erectile dysfunction (ED), also known as impotence, 
            is a medical condition characterized by the persistent
            inability to achieve or maintain an erection sufficient for
            sexual intercourse. It's a common condition that can affect
            men of all ages but becomes more prevalent as men get older.
          </p>
        </div>
      </div>
    </>
  );
};

export default WhatIsErectile;
