import React, { useState, useEffect, useRef } from "react";
import "./CheckoutPage.css";
import axios from "axios";
import { FaCheck } from "react-icons/fa";
import { FaArrowLeftLong } from "react-icons/fa6";
import { FaTruck } from "react-icons/fa";

import useNotification from "../../utils/useNotification";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  add_user_check_product,
  cheke_state,
  get_product_details,
  get_unique_id_data,
} from "../../utils/Constant";
import images from "../../constants/images";
import { RiContactsFill } from "react-icons/ri";
import { MdOutlinePayment } from "react-icons/md";
import { Navbar } from "../../common";

// The self-executing function for stickyio
const stickyio = (function () {
  const iframeUrl = `https://cdn.sticky.io/jssdk/iframe/index.html?appKey=whitelabelmd`;

  const creditCardForm = function (
    appId,
    cardCallback,
    cardValidationCallback
  ) {
    const cardContainer = document.getElementById("stickyio_card");
    const submitButton = document.getElementById("stickyio_submit");
    // const submitButton = document.getElementById("stickyio_submit");

    // Create an iframe only if it doesn't already exist
    let iframe = cardContainer.querySelector("iframe");
    if (!iframe) {
      iframe = document.createElement("iframe");
      iframe.setAttribute("src", iframeUrl);
      iframe.className = "stickyio-iframe";
      cardContainer.appendChild(iframe); // Append the iframe to the existing #stickyio_card div
    }

    const readOnly = function (container = true, button = true) {
      cardContainer.className = container ? "read-only" : "";
      submitButton.className = button
        ? "stickyio-btn read-only"
        : "stickyio-btn";
    };

    // Submit button functionality for tokenization
    if (submitButton) {
      submitButton.className = "stickyio-btn read-only";
      submitButton.onclick = () => {
        readOnly();
        console.log("Sending tokenize request to iframe");
        iframe.contentWindow.postMessage(
          {
            app_id: appId,
            tokenize: true,
          },
          "*"
        );
      };
    }

    // Iframe onload event to initialize card form
    iframe.onload = () => {
      console.log("Iframe loaded");
      iframe.contentWindow.postMessage({ app_id: appId }, "*");
    };

    // Listen for postMessage from iframe (for card token and validation)
    window.addEventListener(
      "message",
      (e) => {
        if (e.data) {
          console.log("Message received from iframe:", e.data);

          // Handle card token received from iframe
          if (e.data.card !== undefined) {
            readOnly(false, true);
            cardCallback(e.data.card);
          }

          // Handle card validation status received from iframe
          if (e.data.valid !== undefined) {
            readOnly(false, !e.data.valid);
            cardValidationCallback(e.data.valid);
          }
        }
      },
      false
    );
  };

  // Append custom styles for the sticky.io iframe and buttons
  function appendStyles() {
    const style = document.createElement("style");
    style.type = "text/css";
    style.innerHTML = `
      .stickyio-btn {
        margin: 0;
        padding: 1em 2em;
        font-size: 14px;
        border: none;
        background-color: #3cb4e5;
        border-radius: 4px;
        cursor: pointer;
        color: white;
        box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.25);
        font-weight: bold;
      }

      .stickyio-btn:hover {
        background-color: #2ca4d5;
      }

      .stickyio-btn:active {
        box-shadow: none;
      }

      .stickyio-iframe {
        border: 0;
        width: 420px;
        height: 115px;
      }

      .read-only {
        opacity: 0.5;
        pointer-events: none;
      }
    `;
    document.getElementsByTagName("head")[0].appendChild(style);
  }

  appendStyles();

  return {
    version: "1.0.0.3",
    creditCardForm: creditCardForm,
  };
})();

// Expose stickyio globally
window.stickyio = stickyio;

const CheckoutPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const name_data = location.state?.name_data;

  const [isloading, setIsLoading] = useState(false);
  const [getdata, setdata] = useState({});
  const [email, setEmail] = useState(name_data?.email);
  const [firstName, setFirstName] = useState(name_data?.first_name);
  const [lastName, setLastName] = useState(name_data?.last_name);
  const [address, setAddress] = useState("");
  const [aptSuite, setAptSuite] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("United States");
  const [getstate, setState] = useState([]);
  const [postalCode, setPostalCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(name_data?.phone);
  const [billingSameAsShipping, setBillingSameAsShipping] = useState(false);
  const [shippingMethod, setShippingMethod] = useState("FREE SHIPPING");
  const [getcardid, setCardid] = useState(1);
  const [cardNumber, setCardNumber] = useState("");
  const [cardholdername, setcardholdername] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [exMonth, setExMonth] = useState("");
  const [exYear, setExYear] = useState("");
  const [cvv, setCvv] = useState("");
  const validateEmail = (email) => {
    // Basic email validation regex
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };
  const { notifySuccess, notifyError } = useNotification();

  const product = location.state?.product;
  const contact_details_id = location.state?.contact_details_id;

  const [paymentToken, setPaymentToken] = useState("");
  const [isCardValid, setIsCardValid] = useState(false);
  const [isSdkReady, setIsSdkReady] = useState(false);
  const [getproductId, setProductId] = useState("");

  const fetchStates = async () => {
    try {
      const res = await axios.post(
        "https://countriesnow.space/api/v0.1/countries/states",
        {
          country: "United States",
        }
      );
      console.log("sfsdcs---->", res.data);
      setState(res.data.data.states);
    } catch (err) {
      console.error("Error fetching states:", err);
    }
  };

  const FetchProductID = async (id) => {
    setIsLoading(true);
    const params = {
      unique_id: id,
    };

    await axios
      .post(get_unique_id_data, params)
      .then((res) => {
        setIsLoading(false);
        console.log("res.data", res.data);
        if (res.data.status == 1) {
          //   setdata(res.data.product_data);
          FetchProduct(res.data.product_id);
          setProductId(res.data.product_id);
        } else {
          setdata({});
          navigate("/ErrorPage");
          notifyError(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        notifyError(err);
        console.log("err", err);
      });
  };

  const FetchProduct = async (id) => {
    setIsLoading(true);
    const params = {
      product_id: id,
    };

    await axios
      .post(get_product_details, params)
      .then((res) => {
        setIsLoading(false);
        console.log("res.data", res.data);
        if (res.data.status == 1) {
          setdata(res.data.product_data);
        } else {
          setdata({});
          navigate("/ErrorPage");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err", err);
      });
  };

  useEffect(() => {
    fetchStates();

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    console.log("location.state", location.state);
    console.log("id", id);
    // FetchProduct(id);
    FetchProductID(id);
  }, []);

  const [getallCitys, setAllCitys] = useState([]);
  const [getselectedState, setSelectedState] = useState({
    name: "",
    state_code: "",
  });
  const [getselectedStateCode, setSelectedStateCode] = useState({
    name: "",
    state_code: "",
  });

  const handleStateChange = async (value) => {
    // setIsLoading(true);
    var params = {
      state_name: value,
    };
    await axios
      .post(cheke_state, params)
      .then((res) => {
        // setIsLoading(false);
        const data = res.data;
        if (data.status === 0) {
          setSelectedState({
            name: "",
            state_code: "",
          });
          setSelectedStateCode({
            name: "",
            state_code: "",
          });
          setAllCitys([]);
          notifyError(data.message);
        } else {
          AllCitys(value);
        }
      })
      .catch((err) => {
        console.log("err", err);
        // setIsLoading(false);
      });
  };

  const AllCitys = async (state) => {
    await axios
      .post("https://countriesnow.space/api/v0.1/countries/state/cities", {
        country: "United States",
        state: state,
      })
      .then((res) => {
        const data = res.data.data;
        console.log("citys", res.data.data);
        setAllCitys(data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const validation = () => {
    if (email === "" || email === null || email === undefined) {
      notifyError("Please enter Email Address.");
      return;
    } else if (!validateEmail(email)) {
      notifyError("Please enter a valid Email Address.");
      return;
    } else if (
      firstName === "" ||
      firstName === null ||
      firstName === undefined
    ) {
      notifyError("Please enter First Name.");
      return;
    } else if (lastName === "" || lastName === null || lastName === undefined) {
      notifyError("Please enter Last Name.");
      return;
    } else if (address === "" || address === null || address === undefined) {
      notifyError("Please enter Address.");
      return;
    } else if (address.length > 34) {
      notifyError("Address cannot exceed 34 characters.");
      return;
    } else if (city === "" || city === null || city === undefined) {
      notifyError("Please enter City.");
      return;
    } else if (
      getselectedStateCode === "" ||
      getselectedStateCode === null ||
      getselectedStateCode === undefined
    ) {
      notifyError("Please select State.");
      return;
    } else if (
      postalCode === "" ||
      postalCode === null ||
      postalCode === undefined
    ) {
      notifyError("Please enter Postal Code.");
      return;
    } else if (
      phoneNumber === "" ||
      phoneNumber === null ||
      phoneNumber === undefined
    ) {
      notifyError("Please enter Phone Number.");
      return;
    } else if (phoneNumber.length < 10) {
      notifyError("Please enter valid Phone Number.");
      return;
    } else if (paymentToken == "") {
      notifyError("Please enter valid payment info");
      return;
    } else {
      //   paymentSubmit();
      //   const param2 = {
      //     email: email,
      //     zip_code: postalCode,
      //     state_name: getselectedStateCode,
      //     first_name: firstName,
      //     last_name: lastName,
      //     product_id: getproductId,
      //     address: address,
      //     city_name: city,
      //     phone: phoneNumber,
      //     contact_details_id: contact_details_id,
      //     payment_token: paymentToken,
      //   };
      checkout_api(paymentToken);

      //   console.log("param2", param2);
    }
  };

  const checkout_api = async (token) => {
    setIsLoading(true);

    // Create a new FormData instance
    const formData = new FormData();

    // Append the param2 data to FormData
    formData.append("email", email);
    formData.append("zip_code", postalCode);
    formData.append("state_name", getselectedStateCode);
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("product_id", getproductId);
    formData.append("address", address);
    formData.append("city_name", city);
    formData.append("phone", phoneNumber);
    formData.append("contact_details_id", contact_details_id);
    formData.append("payment_token", token);

    console.log("FormData created:", formData);

    await axios
      .post(add_user_check_product, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((Response) => {
        setIsLoading(false);
        if (Response.data.status == 1) {
          navigate("/SuccessPage");
        } else {
          console.log("API response failed");
        }
        console.log("API response:", Response.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error during API call:", error);
      });
  };

  const handleCardChange = (event) => {
    const cardValue = parseInt(event.target.value);
    setCardid(cardValue);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    // Only set the CVV if the length is 3 or less and it's numeric
    if (value.length <= 3 && /^\d*$/.test(value)) {
      setCvv(value);
    }
  };

  const formatCardNumber = (cardNumber) => {
    // Add spaces every 4 characters
    return cardNumber.replace(/\D/g, "").replace(/(\d{4})(?=\d)/g, "$1 ");
  };

  const [validEmail, setValidEmail] = useState(true);

  const handleEmailChange = (e) => {
    const input = e.target.value;
    setEmail(input);

    // Basic email validation regex
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValid = emailRegex.test(input);

    setValidEmail(isValid);
  };

  //   const productChange = (id) => {
  //     console.log("e.targate.value", id);
  //     navigate(`/${id}`);
  //     FetchProduct(id);
  //   };

  // new payment function start

  // Initialize the Sticky.io SDK after component mounts
  const initializeStickyIO = () => {
    const appId = "whitelabelmd"; // Replace with your actual appId

    if (window.stickyio) {
      window.stickyio.creditCardForm(
        appId,
        handleTokenSuccess,
        handleCardValidation,
        {
          formSelector: "#stickyio_card", // ID of the div where the card form will be rendered
        }
      );

      setIsSdkReady(true); // Set SDK as ready
      validation();
    } else {
      console.error("Sticky.io SDK is not defined.");
    }
  };

  // Tokenization success handler
  const handleTokenSuccess = (card) => {
    // validation(card.payment_token);
    setPaymentToken(card.payment_token);
    console.log("Token received:", card.payment_token);
    // checkout_api(card.payment_token);
    // notifySuccess("Payment successfully completed");
  };

  // Tokenization error handler
  const handleTokenError = (errors) => {
    console.error("Tokenization error:", errors);
    notifyError(
      "Payment processing failed. Please check your card details and try again."
    );
  };

  // Card validation handler
  const handleCardValidation = (valid) => {
    console.log("Card validation status:", valid);
    setIsCardValid(valid);
  };

  // Form submission handler
  const paymentSubmit = () => {
    if (window.stickyio && isSdkReady) {
      console.log("Attempting to tokenize card");
      // Check if tokenizeCard function exis    ts before calling it
      if (typeof window.stickyio.tokenizeCard === "function") {
        window.stickyio.tokenizeCard(); // Trigger tokenization when the user submits the form
      } else {
        console.error("tokenizeCard function is not available.");
      }
    } else {
      notifyError("Sticky.io SDK is not ready yet. Please try again later.");
    }
  };

  // Initialize SDK after component and #stickyio_card div is rendered
  useEffect(() => {
    const checkStickyIoReady = setInterval(() => {
      if (window.stickyio) {
        initializeStickyIO();
        clearInterval(checkStickyIoReady); // Stop checking once the SDK is ready
      }
    }, 100); // Check every 100ms

    // Cleanup the interval on component unmount
    return () => clearInterval(checkStickyIoReady);
  }, []);

  return (
    <div>
      <style>
        {`
          /* Add your custom CSS rules here */
          #stickyio_cc_number {
            color: blue;
          }
          #stickyio_cc_expiry {
            color: green;
          }
          #stickyio_cc_cvv {
            color: red;
          }
        `}
      </style>
      <Navbar />
      <div className="payment-container">
        <br />
        <div className="payment-content">
          <div className="payment-header_p">
            <p className="paymen_p_tag">Checkout</p>
            {/* <p className="back_menu" onClick={handleGoBack}>
              <FaArrowLeftLong /> back to product
            </p> */}
          </div>
          <div className="payment-content2">
            {/* onSubmit={handleSubmit} */}
            <div className="checkout-form">
              <h3>
                <RiContactsFill />
                Contact Information
              </h3>
              <div className="form-group">
                <input
                  type="email"
                  placeholder="Email Address"
                  value={email}
                  onChange={handleEmailChange}
                  style={{
                    border: validEmail ? "" : "1px solid red",
                  }}
                />
                {!validEmail && (
                  <p style={{ color: "red" }}>
                    Please enter a valid email address.
                  </p>
                )}
              </div>

              <h3>
                <FaTruck />
                Shipping Information
              </h3>
              <div className="form-group-inline">
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
              <div className="form-group-inline">
                <div className="inline_seond">
                  <select
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  >
                    <option>United States</option>
                    {/* Add more country options here */}
                  </select>
                </div>
                <div className="inline_seond">
                  <select
                    value={getselectedState.name}
                    // value={JSON.stringify({
                    //   name: getselectedState.name,
                    //   state_code: getselectedState.state_code,
                    // })}
                    onChange={(e) => {
                      const selectedState = JSON.parse(e.target.value);
                      setSelectedState(selectedState.name);
                      setSelectedStateCode(selectedState.state_code);
                      handleStateChange(selectedState.name);
                      console.log("getselectedStateCode", getselectedStateCode);
                    }}
                  >
                    <option value={""}>Select state</option>
                    {getstate &&
                      getstate.map((states, index) => {
                        return (
                          <option
                            key={states.state_code}
                            value={JSON.stringify({
                              name: states.name,
                              state_code: states.state_code,
                            })}
                          >
                            {states.name}
                          </option>
                        );
                      })}
                    {/* Add more state options here */}
                  </select>
                </div>
              </div>
              <br />
              <div className="form-group-inline">
                <div className="inline_seond">
                  <select
                    value={city}
                    onChange={(e) => {
                      setCity(e.target.value);
                    }}
                  >
                    <option value={""}>Select city</option>

                    {getallCitys.map((city, index) => {
                      return <option value={city}>{city}</option>;
                    })}
                    {/* Add more state options here */}
                  </select>
                </div>
                <div className="inline_seond">
                  <input
                    type="text"
                    placeholder="Postal Code"
                    value={postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                  />
                </div>
              </div>
              <br />

              <div className="form-group">
                <input
                  type="text"
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => {
                    const input = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                    if (input.length <= 10) {
                      setPhoneNumber(input);
                    }
                  }}
                />
              </div>
              {/* <p>All transactions are secure and encrypted.</p> */}
              <br />
              <div className="payment_card">
                <div className="payment_card_titel">
                  <b>
                    <MdOutlinePayment />
                    Payment Methods
                  </b>
                  <div className="card_icon_div">
                    <img
                      src={images.visa}
                      className="item_img_card"
                      alt="Headphones"
                    />
                    <img
                      src={images.master}
                      className="item_img_card"
                      alt="Headphones"
                    />
                    <img
                      src={images.jcb}
                      className="item_img_card"
                      alt="Headphones"
                    />
                    <img
                      src={images.american}
                      className="item_img_card"
                      alt="Headphones"
                    />
                  </div>
                </div>
                <div className="details-card">
                  {/* <div className="form-group">
                    <select
                      className="card-dropdown"
                      onChange={handleCardChange}
                      value={getcardid}
                    >
                      <option value={1}>Visa</option>
                      <option value={2}>MasterCard</option>
                      <option value={3}>Discover</option>
                      <option value={4}>American Express</option>
                    </select>
                  </div> */}
                  {/* <div className="form-group">
                    <input
                      type="text"
                      placeholder="Card holder name"
                      className="card-number"
                      value={cardholdername}
                      onChange={(e) => setcardholdername(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Card Number"
                      className="card-number"
                      value={formatCardNumber(cardNumber)}
                      onChange={(e) => {
                        const input = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                        if (input.length <= 16) {
                          setCardNumber(input);
                        }
                      }}
                    />
                  </div> */}
                  {/* <div className="form-group-inline">
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="MM/YY"
                        className="expiry-date"
                        value={expiryDate}
                        onChange={(e) => {
                          let input = e.target.value;
                          // Remove non-numeric characters except "/"
                          input = input.replace(/\D/g, "");

                          // Ensure input is limited to 4 characters (MMYY format)
                          if (input.length > 4) {
                            input = input.slice(0, 4);
                          }

                          // Extract MM and YY
                          const month = input.slice(0, 2);
                          const year = input.slice(2, 4);

                          // Update state for ex_month and ex_year
                          setExMonth(month);
                          setExYear(year);

                          // Format input to display MM/YY in the input field
                          setExpiryDate(
                            month + (year.length > 0 ? "/" + year : "")
                          );
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="CVV"
                        className="cvv"
                        value={cvv}
                        onChange={handleChange}
                        maxLength={3}
                      />
                    </div>
                  </div> */}
                  {/* <div className="note_titel_"> */}
                  <form
                    id="stickyio_order_form"
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <div>
                      <label>Token: </label>
                      <input
                        type="text"
                        name="payment_token"
                        id="stickyio_payment_token"
                        value={paymentToken}
                        readOnly
                      />
                    </div>

                    {/* Include the sticky.io card form container */}
                    <div id="stickyio_card"></div>

                    {/* Payment submission button */}
                    <button
                      className="stickyio-btn"
                      id="stickyio_submit"
                      //   disabled={!isCardValid} // Disable button if card is not valid
                      //   onClick={validation}
                      onClick={paymentSubmit}
                    >
                      Submit Payment
                    </button>
                  </form>
                  {/* </div> */}
                  <center>
                    {/* <button
                      className="btn-checkout"
                      id="stickyio_submit2"
                      onClick={validation}
                    >
                      Complete validation
                    </button> */}
                  </center>
                </div>
              </div>
            </div>
            <div className="order-summary">
              <h3>Order Summary</h3>
              <div className="order-table">
                <div className="item-img">
                  <img
                    src={
                      getdata?.product_img && getdata.product_img.length > 0
                        ? getdata.product_img[0]?.img_video
                        : null
                    }
                    className="item_img"
                    alt="Headphones"
                  />
                </div>
                <div className="item-description">
                  <span>{getdata?.product_name}</span>

                  {/* <select
                    value={country}
                    onChange={(e) => productChange(e.target.value)}
                    style={{
                      marginBottom: "10px",
                      padding: "6px",
                      width: "100%",
                      fontSize: "14px",
                      fontWeight: "500",
                      paddingRight: "1rem",
                    }}
                  >
                    <option selected value={getdata.product_id}>
                      {getdata?.product_name}
                    </option> */}
                  {/* Add more country options here */}

                  {/* {getdata && getdata.product && getdata.product.length > 0
                      ? getdata.product.map((item, index) => {
                          return getdata?.product_name !== item.product_name ? (
                            <option key={index} value={item.product_id}>
                              {item.product_name}
                            </option>
                          ) : null;
                        })
                      : null}
                  </select> */}

                  <br />
                  <b> ${getdata?.product_price} </b>
                </div>
              </div>
              <hr className="hr" />
              <div className="total_div">
                <b>Grand Total</b>
                <b>${getdata?.product_price}</b>
              </div>
              <div className="details_order">
                <p>
                  <FaCheck />
                  Provider & Medication Included{" "}
                </p>
                <p>
                  <FaCheck />
                  On Demand Virtual Doctor Visits{" "}
                </p>
                <p>
                  <FaCheck />
                  30 Days Weight Loss Guarantee
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPage;
