import React from "react";
import "./HeroSection6.css";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { GoCheckCircle } from "react-icons/go";
import { images } from "../../constants";

const HeroSection6 = () => {
  return (
    <div className="six_page_hero_main_wrapp">
      <div className="six_page_hero_base_wrapp">
        <div className="six_page_hero_left">
          <img src={images.page6_hero} alt="" />
        </div>
        <div className="six_page_hero_right">
          <h1>
            Stand strong against hair loss: Finasteride for lasting results
          </h1>
          <div className="six_hero_content_div">
            <div className="six_hero_content_left">
              <div className="six_hero_option_div"></div>

              <div className="six_hero_med_del">
                <div>
                  <h3 className="six_med_name">Finasteride</h3>
                  <h4 className="six_supply">1 month supply</h4>
                  <p className="six_shipment">xx content amount per shipment</p>
                </div>
                <div>
                  <p className="six_startingat">STARTING AT</p>
                  <h3 className="six_med_price">
                    {/* <span className="dollor_symbol">$</span> */}
                    $00
                  </h3>
                </div>
              </div>

              <button className="start_my_visit_btn btn">
                COMING SOON
                <MdOutlineKeyboardArrowRight fontSize={30} />
              </button>
            </div>
            <div className="six_hero_content_right">
              <div className="grey-circle6"></div>
              <img src={images.mens_medicine} alt="" />
            </div>
          </div>
          <div className="six_hero_last_div">
            <p>
              <div>
                <GoCheckCircle fontSize={25} />
              </div>
              <span>100% online, confidential consultations</span>
            </p>
            <p>
              <div>
                <GoCheckCircle fontSize={25} />
              </div>
              <span>US board-certified doctors, trusted medications</span>
            </p>
            <p>
              <div>
                <GoCheckCircle fontSize={25} />
              </div>
              <span>Free rushed delivery from the pharmacy</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection6;
