import React, { useEffect } from "react";
import "./EDPage.css";
import { Footer, Navbar } from "../../common";
import { FAQ } from "../../components";
import {
  ClientSlider,
  GetPrescription,
  SecoundHero,
  ThreeButtons,
  WhatIsErectile,
} from "../../containers";

const EDPage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);
  return (
    <>
      <Navbar />
      <SecoundHero />
      <ClientSlider />
      <WhatIsErectile />
      <GetPrescription />
      <ThreeButtons
        name1="NO INSURANCE HASSULE"
        name2="NO OUT-OF-STOCK ANXIETY"
        name3="CONNECT WITH A REAL PHYSICIAN"
      />
      <FAQ />
      <Footer />
    </>
  );
};

export default EDPage;
