import React, { useEffect } from "react";

import { Footer, Navbar } from "../../common";
import { FAQ } from "../../components";
import {
  ClientSlider,
  GetPrescription,
  HeroSection6,
  SecoundHero,
  ThreeButtons,
  WhatIsErectile,
  WhatIsHairLoss,
} from "../../containers";

const Page6 = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);
  return (
    <>
      <Navbar />
      <HeroSection6 />
      <ClientSlider />
      <WhatIsHairLoss />
      <GetPrescription />
      <ThreeButtons
        name1="NO INSURANCE HASSULE"
        name2="NO OUT-OF-STOCK ANXIETY"
        name3="CONNECT WITH A REAL PHYSICIAN"
      />
      <FAQ />
      <Footer />
    </>
  );
};

export default Page6;
