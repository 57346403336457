import React from "react";
import "./TrustedMedication.css";
import { TMCard } from "../../components";
import { images } from "../../constants";

const TrustedMedication = () => {
  return (
    <div className="section section-center">
      <div className="tm_main_wrapp">
        <div className="tm_heading_wrapp">
          <h2>TRUSTED MEDICATIONS.</h2>
          <h3>UNBEATABLE PRICES.</h3>
        </div>
        <div className="tm_cards_wrapp">
          <TMCard name="weight loss" image={images.weight_loss} />
          <TMCard name="erectile dysfunction" image={images.kiss_copple} />
          <TMCard name="hair loss" image={images.hair_loss} />
        </div>
      </div>
    </div>
  );
};

export default TrustedMedication;
