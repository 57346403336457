import React, { useState } from "react";
import "./faq.css";
import images from "../../constants/images";
import { IoArrowDownCircle } from "react-icons/io5";

const FAQ = ({ onClickHandle }) => {
  const [openFAQ, setOpenFAQ] = useState(null);

  const toggleFAQ = (index) => {
    setOpenFAQ(openFAQ === index ? null : index);
  };

  return (
    <div className="faq-container">
      <div className="section faq_wrapp">
        <h2 className="faq-title">Frequently Asked Questions</h2>

        <div className="faq-item">
          <div className="faq-question" onClick={() => toggleFAQ(1)}>
            <span>WHAT IS MENS RX?</span>
            <IoArrowDownCircle
              fontSize={30}
              className={`faq-img ${openFAQ === 1 ? "rotate" : ""}`}
            />
          </div>
          {openFAQ === 1 && (
            <div className="faq-answer">
              <p>
                Mens RX is a health service designed to help men achieve optimal
                wellness through personalized treatment.
              </p>
            </div>
          )}
        </div>
        <hr />
        <div className="faq-item">
          <div className="faq-question" onClick={() => toggleFAQ(2)}>
            <span>WHAT IS THE MENS RX PROCESS?</span>
            <IoArrowDownCircle
              fontSize={30}
              className={`faq-img ${openFAQ === 2 ? "rotate" : ""}`}
            />
          </div>
          {openFAQ === 2 && (
            <div className="faq-answer">
              <p>
                The process includes a medical evaluation, personalized
                treatment plan, and follow-up support to ensure the best
                results.
              </p>
            </div>
          )}
        </div>
        <hr />
        <div className="faq-item">
          <div className="faq-question" onClick={() => toggleFAQ(3)}>
            <span>HOW MUCH DOES MENS RX COST?</span>
            <IoArrowDownCircle
              fontSize={30}
              className={`faq-img ${openFAQ === 3 ? "rotate" : ""}`}
            />
          </div>
          {openFAQ === 3 && (
            <div className="faq-answer">
              <p>
                The cost of Mens RX varies based on the treatment plan and
                services selected. Contact us for more details.
              </p>
            </div>
          )}
        </div>
        <hr />
        <div className="faq-footer">
          <button className="btn" onClick={onClickHandle && onClickHandle}>
            START MY VISIT
            <img src={images.right_arrow} className="rightarrow" alt="" />{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
