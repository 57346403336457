import React, { useState, useEffect } from "react";
import "./mensrxhero.css";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import images from "../../constants/images";

const Mensrxhero = ({ onClickHandle }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="mensrx_hero_full_div">
      <div className="mensrx_hero_main_wrapp">
        <div className="mensrx_hero_overlay"></div>
        <div className="mensrx_hero_base_wrapp">
          <div className="hero_title_div div7">
            <h1>
              MAXIMUM HEALTH <br />
              AND WELLNESS FOR MEN
            </h1>
            <p>
              <div>
                <img
                  src={images.tetehealth}
                  className="hero_title_div_img"
                  alt=""
                />
              </div>
              Includes all private confidential telehealth consultations
            </p>
            <p>
              <div>
                <img
                  src={images.medications}
                  className="hero_title_div_img"
                  alt=""
                />
              </div>
              Trusted medications from US accredited pharmacies
            </p>
            <p>
              <div>
                <img src={images.power} className="hero_title_div_img" alt="" />
              </div>
              Unmatched prices with no hidden fees
            </p>
            <button className="btn" onClick={onClickHandle && onClickHandle}>
              START MY VISIT
              <MdOutlineKeyboardArrowRight fontSize={30} />
            </button>
          </div>
          <div className="hero_med_img_circle">
            <img
              src={images.medical1}
              alt=""
              className="semagla_med_img_hero"
            />
            <img src={images.medical2} alt="" className="tirze_med_img_hero" />
            <img src={images.mens_medicine} alt="" className="hero_med_img3" />
          </div>
        </div>

        <div className="content-h2">
          <div>
            <img
              src={isMobile ? images.badge_white : images.badge} // Switch images here
              className="content_div_img"
              alt=""
            />
          </div>
          <h2>BACKED WITH OUR 60 DAY MONEY BACK GUARANTEE!</h2>
        </div>
        <hr className="mensrx_hero_hr" />
      </div>
    </div>
  );
};

export default Mensrxhero;
