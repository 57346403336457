import React, { useEffect } from "react";
import { Footer, Footer2, Navbar, NavbarBlack } from "../common";
import {
  ClientSlider,
  GetYourPrescription,
  HeroSection7,
  Mensrxhero,
  ThreeButtons,
  TrustedMedication,
  WhatsIndclude,
} from "../containers";
import { FAQ2 } from "../containers";
import Mensrxhero2 from "../containers/mensrxhero/Mensrxhero2";

const HomePage2 = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);
  return (
    <>
      <NavbarBlack />
      <HeroSection7 />
      <TrustedMedication />
      <WhatsIndclude />
      <ThreeButtons
        name1="NO INSURANCE HASSULE"
        name2="NO OUT-OF-STOCK ANXIETY"
        name3="CONNECT WITH A REAL PHYSICIAN"
      />
      <GetYourPrescription />
      <ClientSlider />
      <FAQ2 />
      <Footer2 />
    </>
  );
};

export default HomePage2;
