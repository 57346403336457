import React, { useState } from "react";
import "./HeroSection5.css";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { GoCheckCircle } from "react-icons/go";
import { images } from "../../constants";

const HeroSection5 = () => {
  return (
    <div className="five_page_hero_main_wrapp">
      <div className="five_page_hero_base_wrapp">
        <div className="five_page_hero_left">
          <img src={images.page5_hero} alt="" />
        </div>
        <div className="five_page_hero_right">
          <h1>Lose up to 25% of yourbody weight with GLP-1 Tirzepatide</h1>
          <div className="five_hero_content_div">
            <div className="five_hero_content_left">
              <div className="five_hero_option_div"></div>

              <div className="five_hero_med_del">
                <div>
                  <h3 className="five_med_name">Tirzepatide</h3>
                  <h4 className="five_supply">1 month supply</h4>
                  <p className="five_shipment">
                    xx content amount per shipment
                  </p>
                </div>
                <div>
                  <p className="five_startingat">STARTING AT</p>
                  <h3 className="five_med_price">
                    {/* <span className="dollor_symbol">$</span> */}
                    $00
                  </h3>
                </div>
              </div>

              <button className="start_my_visit_btn btn">
                COMING SOON
                <MdOutlineKeyboardArrowRight fontSize={30} />
              </button>
            </div>
            <div className="five_hero_content_right">
              <div className="grey-circle5"></div>
              <img src={images.tirzepatide_img} alt="" />
            </div>
          </div>
          <div className="hipaa_img_div">
            <img src={images.HIPAA_img} alt="" />
          </div>
          <div className="five_hero_last_div">
            <p>
              <div>
                <GoCheckCircle fontSize={25} />
              </div>
              <span>100% online, confidential consultations</span>
            </p>
            <p>
              <div>
                <GoCheckCircle fontSize={25} />
              </div>
              <span>US board-certified doctors, trusted medications</span>
            </p>
            <p>
              <div>
                <GoCheckCircle fontSize={25} />
              </div>
              <span>Free rushed delivery from the pharmacy</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection5;
