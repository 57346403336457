import React from "react";
import "./TMCard.css";
import { FaChevronRight } from "react-icons/fa";

const TMCard = ({ image, name }) => {
  return (
    <div className="tm_card_wrapp">
      <div className="tm_card_img_wrapp">
        <img src={image} alt="" />
      </div>
      <div className="tm_card_btn_wrapp">
        <h4>{name}</h4>
        <span>
          <FaChevronRight />
        </span>
      </div>
    </div>
  );
};

export default TMCard;
