// ---------------- logos ------------------
import black_logo from "../assets/logos/logo_black.jpg";
import white_logo from "../assets/logos/logo_white.jpg";

import dashbord_logo from "../assets/logos/dashbord_logo.png";
import help_logo from "../assets/logos/help_logo.png";
import massage_logo from "../assets/logos/massage_logo.png";
import order_logo from "../assets/logos/order_logo.png";
import security_logo from "../assets/logos/security_logo.png";
import setting_logo from "../assets/logos/setting_logo.png";
import user_logo from "../assets/logos/user_logo.png";
import notification_logo from "../assets/logos/notification_logo.png";
import hipaa_logo from "../assets/logos/hipaa_logo.png";

import calendar_icon from "../assets/logos/calendar_icon.png";
import uparrow_icon from "../assets/logos/uparrow_icon.png";

import msg_send_icon from "../assets/logos/msg_send_icon.png";
import file_logo from "../assets/logos/file_logo.png";
import logout_icon from "../assets/logos/logout_icon.png";
import favicon from "../assets/logos/favicon.png";
import down_arrow from "../assets/logos/down_arrow.png";
import right_arrow from "../assets/logos/right-arrow.png";
import facebook from "../assets/logos/facebook.png";
import instagram from "../assets/logos/instagram.png";
import vogue from "../assets/logos/vogue.png";
import mens_fitness from "../assets/logos/mens_fitness.png";
import HIPAA_white from "../assets/logos/HIPAA_white.png";

import NBC_logo from "../assets/logos/NBC.png";
import cnnlogo from "../assets/logos/cnn-logo.png";
import today from "../assets/logos/today.png";
import GQ_Logo from "../assets/logos/GQ.png";
import Fox_News from "../assets/logos/Fox_News.png";
import Yahoo_news_logo from "../assets/logos/Yahoo_news_logo.png";

import power from "../assets/logos/energy.png";
import medications from "../assets/logos/medications.png";
import tetehealth from "../assets/logos/tetehealth.png";

import badge from "../assets/logos/badge.png";
import badge_white from "../assets/logos/badge_white.png";

import american from "../assets/logos/american.svg";
import master from "../assets/logos/master.svg";
import jcb from "../assets/logos/jcb.svg";
import visa from "../assets/logos/visa.svg";

// ---------------- images ------------------
import sidebar_user_img from "../assets/images/sidebar_user_img.png";
import product_1 from "../assets/images/product_1.png";
import login_back_img from "../assets/images/login_back_img.png";
import hero_background from "../assets/images/hero_background.jpg";
import hero_background1 from "../assets/images/hero_background1.png";
import hero_background2 from "../assets/images/hero_background2.jpg";
import medicine from "../assets/images/medicine.png";
import hair_loss from "../assets/images/hair_loss.jpg";
import weight_loss from "../assets/images/weight_loss.png";
import male_doctor from "../assets/images/male_doctor.jpg";
import body_man from "../assets/images/body_man.jpg";
import erectile_dysfunction from "../assets/images/erectile_dysfunction.jpg";
import second_hero from "../assets/images/second_hero.png";
import semaglutide_img from "../assets/images/semaglutide_img.png";
import tirzepatide_img from "../assets/images/tirzepatide_img.png";
import man_with_apple from "../assets/images/man_with_apple.png";
import page4_hero from "../assets/images/page4_hero.png";
import page5_hero from "../assets/images/page5_hero.png";
import page6_hero from "../assets/images/page6_hero.png";
import mens_medicine from "../assets/images/mens_medicine.png";
import HIPAA_img from "../assets/images/HIPAA_img.png";
import medical1 from "../assets/images/medical1.png";
import medical2 from "../assets/images/medical2.png";
import card_img1 from "../assets/images/card1.png";
import card_img2 from "../assets/images/card2.png";
import card_img3 from "../assets/images/card3.png";
import card_img4 from "../assets/images/card4.png";
import kiss_copple from "../assets/images/kiss_copple.png";
import box_img from "../assets/images/box_img.png";
import man_with_towel from "../assets/images/man_with_towel.png";
import man_with_selad from "../assets/images/man_with_selad.png";
import hairwash from "../assets/images/hairwash.jpeg";
import box_img_2 from "../assets/images/box_img_2.png";

export default {
  // ---------------- logos ------------------
  black_logo,
  white_logo,

  dashbord_logo,
  help_logo,
  massage_logo,
  order_logo,
  security_logo,
  setting_logo,
  user_logo,
  notification_logo,
  hipaa_logo,

  calendar_icon,
  uparrow_icon,

  msg_send_icon,
  file_logo,
  logout_icon,
  favicon,
  down_arrow,
  right_arrow,
  facebook,
  instagram,
  vogue,
  mens_fitness,
  HIPAA_white,
  NBC_logo,
  cnnlogo,
  today,
  GQ_Logo,
  Fox_News,
  Yahoo_news_logo,
  power,
  medications,
  tetehealth,
  badge,

  visa,
  master,
  jcb,
  american,

  // ---------------- images ------------------
  sidebar_user_img,
  product_1,
  login_back_img,

  hero_background,
  hero_background1,
  hero_background2,
  medicine,
  hair_loss,
  weight_loss,
  male_doctor,
  body_man,
  erectile_dysfunction,
  second_hero,
  semaglutide_img,
  tirzepatide_img,
  man_with_apple,
  page4_hero,
  page5_hero,
  page6_hero,
  mens_medicine,
  HIPAA_img,
  medical1,
  medical2,
  card_img1,
  card_img2,
  card_img3,
  card_img4,
  kiss_copple,
  box_img,
  box_img_2,
  man_with_towel,
  man_with_selad,
  hairwash,
  badge_white,
};
