import React from "react";
import "./GetPrescription.css";
import { GPCard } from "../../components";
import { images } from "../../constants";

const GetPrescription = () => {
  return (
    <div className="section section-center">
      <div className="tm_main_wrapp">
        <div
          className="tm_heading_wrapp"
          style={{ gap: "3rem", justifyContent: "flex-start" }}
        >
          <h2>GET YOUR PRESCRIPTION IN 3 EASY STEPS</h2>
          <div className="tm_cards_wrapp">
            <GPCard
              name="TAKE THE HEALTH QUESTIONNAIRE"
              image={images.man_with_towel}
            />
            <GPCard name="GET YOUR PRESCRIPTION" image={images.male_doctor} />
            <GPCard name="DISCREETLY SHIPPED TO YOU" image={images.box_img_2} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetPrescription;
