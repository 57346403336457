import React, { useContext, useEffect, useState } from "react";
import { Footer, Loading, Navbar } from "../common";
import { useLocation } from "react-router-dom";

const InakeForm = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();

  // useEffect(() => {
  //   // Set a timeout to hide the loading indicator after 3 seconds
  //   const timer = setTimeout(() => {
  //     setIsLoading(false);
  //   }, 3000);
  //   console.log(
  //     "`https://forms.whitelabelmd.com/${location.state.form_id}`",
  //     `https://forms.whitelabelmd.com/${location.state.form_id}`
  //   );

  //   // Cleanup the timeout if the component is unmounted before the timer ends
  //   return () => clearTimeout(timer);
  // }, []);

  useEffect(() => {
    // Set a timeout to hide the loading indicator after 3 seconds
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    // Check if the screen width is less than 768px (mobile view)
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Run the resize handler on mount and add a listener
    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup timeout and event listener
    return () => {
      clearTimeout(timer);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Navbar />
      <div className="dashbord_main_wrapp">
        <div className="dashbord_base_wrapp">
          {/* {status == 0 ? (
          <div
            style={{
              width: "100%",
              height: "50vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p>You have already submitted form</p>
          </div>
        ) : ( */}
          <iframe
            id="inlineFrameExample"
            title="Inline Frame Example"
            width="100%"
            height="800"
            src={`https://forms.whitelabelmd.com/${location.state.form_id}`}
          ></iframe>
          {/* )} */}
        </div>
      </div>
      {!isMobile && <Footer />}
    </>
  );
};

export default InakeForm;
