const BaseUrl =
  "https://lightgoldenrodyellow-okapi-586794.hostingersite.com/mensrs/api/";

export const products_url = BaseUrl + "get_products";
export const get_product_details = BaseUrl + "get_product_details";
export const add_user_check_product = BaseUrl + "add_user_check_product";
export const cheke_state = BaseUrl + "cheke_state";
export const get_unique_id_data = BaseUrl + "get_unique_id_data";

// export const cheke_state = BaseUrl + "cheke_state";
// export const add_user_check_product = BaseUrl + "add_user_check_product";
// export const contact_details_step_25 = BaseUrl + "contact_details_step_25";
// export const get_product_details = BaseUrl + "get_product_details";
