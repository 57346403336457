import React from "react";
import "./WhatIsSemaglutide.css";
import images from "../../constants/images";
import { FaCheck } from "react-icons/fa6";

const WhatIsSemaglutide = ({ image, name, des1, des2, des3, btn1 }) => {
  return (
    <div className="section section-center">
      <div className="wis_main_wrapp">
        <div className="wis_sec1">
          <div className="wis_sec1_img_wrapp">
            <img src={image} alt="" />
          </div>
          <div className="wis_sec1_text_wrapp">
            <h6>{name}</h6>
            <p>{des1}</p>
            <p>{des2}</p>
            <p>{des3}</p>
          </div>
        </div>
        <div className="wis_sec2">
          <div className="wis_sec2_btn1">
            <FaCheck color="var(--clr-black)" size={20} />
            <p>{btn1}</p>
          </div>
          <div className="wis_sec2_btn2">
            <FaCheck color="var(--clr-mint)" size={20} />
            <p>Feel fuller faster & longer</p>
          </div>
          <div className="wis_sec2_btn2">
            <FaCheck color="var(--clr-mint)" size={20} />
            <p>Same active ingredient as Ozempic® and Wegovy®</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatIsSemaglutide;
