import React, { useState, useEffect } from "react";
import "./HeroSection7.css";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import images from "../../constants/images";

const HeroSection7 = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="pageseven_hero_full_div">
      <div className="pageseven_hero_main_wrapp">
        <div className="pageseven_hero_overlay"></div>
        <div className="pageseven_hero_base_wrapp">
          <div className="hero_title_div">
            <h1>
              MAXIMUM HEALTH <br />
              AND WELLNESS FOR MEN
            </h1>
            <p>
              <div>
                <img
                  src={images.tetehealth}
                  className="hero_title_div_img"
                  alt=""
                />
              </div>
              Includes all Private confidental tetehealth consultations
            </p>
            <p>
              <div>
                <img
                  src={images.medications}
                  className="hero_title_div_img"
                  alt=""
                />
              </div>
              Trusted medications from US accredited pharmacies
            </p>
            <p>
              <div>
                <img src={images.power} className="hero_title_div_img" alt="" />
              </div>
              Unmatched prices with no hidden fees
            </p>
            <div className="pageseven_btn_img_div">
              <button className="btn">
                COMING SOON
                <MdOutlineKeyboardArrowRight fontSize={30} />
              </button>
              <img src={images.HIPAA_white} alt="" />
            </div>
          </div>
        </div>

        <div className="content_h2_p7">
          <div></div>
          <h2>BACKED WITH OUR 60 DAY MONEY BACK GUARANTEE!</h2>
        </div>
        <hr className="pageseven_hero_hr" />
      </div>
    </div>
  );
};

export default HeroSection7;
